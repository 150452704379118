import $ from 'jquery'
import { throttle } from './throttle'

class ScrollBar {
  constructor() {}

  // Usage:
  initialize() {
    this.scrollBars = $('.js-scrollbar')
    this.scrollTrack = $('.js-scrollbar-track')
    this.beforeScrollPos = window.scrollY

    const onScroll = throttle((e) => this.onScroll(e), 50)
    window.addEventListener('scroll', onScroll)
    $('.js-scrollbar-thumb').on('click', () => {
      $("html,body").animate({scrollTop:0}, 300);
    })
  }

  onScroll(e) {

    if(!this.scrollTrack) return
    const scrollY = window.scrollY
    const docH = document.body.clientHeight
    const trackHeight = this.scrollTrack.eq(0).height()

    const scrollDiff = scrollY - this.beforeScrollPos
    const isScrollUp = scrollDiff < 0
    const dir = scrollDiff === 0 ? this.beforeDir : (isScrollUp ? 'up' : 'down')
    this.scrollBars.each((i, d) => {
      const rect = d.getBoundingClientRect()

      const isShort = rect.height < window.innerHeight
      const marginTop = window.innerHeight / 5
      const marginBottom = window.innerHeight / 5

      const top = rect.top + scrollY - marginTop
      const height = isShort ? docH - window.innerHeight : rect.height - window.innerHeight + marginBottom
      const bottom = top + height
      const scrollPos = isShort ? scrollY : scrollY - top
      const $thumb = $(d).find('.js-scrollbar-thumb')

      let per = (scrollPos / height) * 100

      per = Math.min(Math.max(per, 0), 100)
      const pos = trackHeight * (per * 0.01)

      if(this.beforeDir === 'up' && dir === 'up') {
        $thumb.css({
          transform: `translate3d(0, ${pos}px, 0) rotate(180deg)`
        })
      } else if(this.beforeDir === 'down' && dir === 'down') {
        $thumb.css({
          transform: `translate3d(0, ${pos}px, 0)`
        })
      }

      if(100 <= per) {
        $thumb.css({
          transform: `translate3d(0, ${pos}px, 0) rotate(180deg)`
        })
      } else if( per <= 0) {
        $thumb.css({
          transform: `translate3d(0, ${pos}px, 0)`
        })
      }
    })
    this.beforeScrollPos = scrollY
    this.beforeDir = dir
  }
}
export default new ScrollBar()
