import $ from 'jquery'

export const scrollLoader = () => {
  const $list = $('.js-scroll-loader-list')
  const $more = $('.js-scroll-loader-more')
  let page = 2
  let isLoading = false
  $more.on('click', () => {
    if(isLoading) return
    isLoading = true
    $more.addClass('loading')
    const url = $more.attr('url')
    const prefix = $more.attr('prefix')
    $.ajax({
      type: 'GET',
      url: url + '?page=' + page,
      dataType : 'json',
    }).done((d) => {
      $more.removeClass('loading')
      isLoading = false
      ++page
      const loadedArticles = d.news.map((article) => {
        return `
          <li class="${prefix}-NewsCell">
            <a href="/ja/news/${article.formatted_id}" class="${prefix}-NewsCell_Link">
              <h3 class="${prefix}-NewsCell_Title">
                <span class="${prefix}-NewsCell_TitleInner">
                  ${article.title}
                </span>
              </h3>
              <time class="${prefix}-NewsCell_Date">${article.formatted_publish_date}</time>
            </a>
          </li>
        `
      })

      $list.append(loadedArticles)
      if(d.total_pages < page) {
        $more.addClass('hidden')
      }
    }).fail((e) => {
      $more.removeClass('loading')
      alert('読み込みに失敗しました。\r時間を置いて再度お試しください')
      isLoading = false
    })
  })
}
