import $ from 'jquery'
import 'slick-carousel'

class Slider {
  constructor() {}

  initialize() {
    let slickTarget = $('.js-slider')
    slickTarget.slick({
      centerMode: true,
      autoplay: true,
      arrows: true,
      dots: true,
      centerPadding: '33.5%',
      infinite: true,
      prevArrow: '<button class="slick-Btn slick-prevBtn"><i></i></button>',
      nextArrow: '<button class="slick-Btn slick-nextBtn"><i></i></button>',
      slidesToShow: 1,
      slideToScroll: 1,
      autoplaySpeed: 4000,
      responsive: [{
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerPadding: '15%',
        }
      }]
    })

    slickTarget = $('.js-member-slider')
    slickTarget.slick({
      centerMode: true,
      autoplay: true,
      arrows: true,
      dots: false,
      centerPadding: '0',
      infinite: true,
      prevArrow: '<button class="slick-Btn slick-prevBtn"><i></i></button>',
      nextArrow: '<button class="slick-Btn slick-nextBtn"><i></i></button>',
      slidesToShow: 5,
      slideToScroll: 5,
      autoplaySpeed: 4000,
    })

    slickTarget = $('.js-firstlive-slider')
    slickTarget.slick({
      centerMode: true,
      autoplay: false,
      arrows: true,
      dots: true,
      centerPadding: '0',
      infinite: true,
      prevArrow: '<button class="slick-Btn slick-prevBtn"><i></i></button>',
      nextArrow: '<button class="slick-Btn slick-nextBtn"><i></i></button>',
      slidesToShow: 1,
      slideToScroll: 1,
      autoplaySpeed: 4000,
      responsive: [{
        breakpoint: 768,
        settings: {
          arrows: false,
        }
      }]
    })
  }
}
export default new Slider()
